<template>
    <div class="">

        <span class="price-start"
            v-html="getPriceText"></span>

        <span class="more-price-info"> 
        </span>

    </div>

</template>
<script>
    import V from 'voUtils/V.js';
    import DateEx from 'voUtils/DateEx.js';

    export default {
    	voVueComponent: 'minrent',
    	props: {
    		pricelist: Array,
    		minrent: Number
    	},
    	data: function() {
    		return {
    			totalStars: 5
    		};
    	},
    	computed: {
    		getPriceText: function() {
    			var priceText = '';
    			var minrent = 0;
    			var priceCleaning = 0;
    			var placeRent = 0;
    			var total = 0;
    			var mandatory = 0;

    			//console.log('Pricelist::', this.pricelist);

    			if (this.minrent && this.pricelist) {
    				minrent = this.minrent;

    				for (var i = 0; i < this.pricelist.length; i++) {

    					if (this.pricelist[i].service === 47050) {
    						placeRent = this.pricelist[i].value;
    						//console.log('placeRent::', placeRent);
    						//	priceText += ' Service 38897:' + this.pricelist[i].value;
    					}

    					total = minrent + placeRent;
    				}


    				priceText += ' <span class="small">ab</span> <span class="h3"><strong>';
    				priceText += Number(total / 100).toLocaleString("de-DE", {
    					minimumFractionDigits: 2
    				}) + " € </span>";
    				priceText += '<span class="small">pro Nacht</span>';

    			}

    			return priceText;
    		},
    		getMinRentText: function() {
    			var minRentText = '';

    			if (this.minrent) {
    				minRentText += Number(this.minrent / 100).toLocaleString("de-DE", {
    					minimumFractionDigits: 2
    				}) + " €";
    			}

    			return minRentText;
    		}
    	}
    };
</script>
<style scoped>
    .pricehelp {
    	font-size: 13px;
    	color: #666666;

    }
</style>