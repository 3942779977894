<template>
    <a href="javascript:void(0);"
        data-toggle="popover"
        :title="title"
        data-trigger="click"
        data-placement="top"
        data-html="true"
        :data-content="getOfferText">
        <span class="offer-left">{{voMsg('offer.title')}}</span>
    </a>

</template>
<script>
    import V from 'voUtils/V.js';
    import DateEx from 'voUtils/DateEx.js';

    import {
    	getVoucher
    }
    from 'voUtils/VoucherStore.js';

    export default {
    	voVueComponent: 'offer-help',
    	props: {
    		offers: Array
    	},
    	mounted: function() {

    		$('[data-toggle="tooltip"]').tooltip();
    		$('[data-toggle="popover"]').popover();
    		$('body').on('click', function(e) {
    			$('[data-toggle=popover]').each(function() {
    				if (!$(this).is(e.target) && $(this).has(e.target).length === 0 && $('.popover').has(e.target).length === 0) {
    					(($(this).popover('hide').data('bs.popover') || {}).inState || {}).click = false;
    				}
    			});
    		});




    	},
    	methods: {
    		getVoucherCode: function() {
    			var voucher = getVoucher();
    			if (voucher) {
    				return voucher.code;
    			}
    			return null;
    		},

    	},
    	computed: {

    		getOfferText: function() {
    			var offerText = '';
    			if (this.offers) {
    				this.offers.sort((a, b) => (a.fromdate > b.fromdate) ? 1 : -1);
    				var i;
    				offerText = "<ul>";
    				for (i = 0; i < this.offers.length; i++) {
    					var offer = this.offers[i];
    					if (!offer.voucherCode || offer.voucherCode === this.getVoucherCode()) {
    						var offer = this.offers[i];

    						offerText += "<li>";

    						offerText += offer.name + " ";
    						if (offer.discountType === 'PERCENT') {
    							offerText += "- " + offer.value / 100 + "% " + this.voMsg('offer.discount'); + " ";
    						}

    						if (offer.type === 'EARLYBIRD') {
    							if (offer.timeSpan === 1) {
    								offerText += "bei Anreise in frühestens einem Tag ";
    							} else if (offer.timeSpan > 1) {
    								offerText += "bei Anreise in frühestens " + offer.timeSpan + " Tagen ";
    							}

    						}

    						if (offer.type === 'LASTMINUTE') {
    							if (offer.timeSpan == 1) {
    								offerText += "bei Anreise in spätestens einem Tag ";
    							} else if (offer.timeSpan > 1) {
    								offerText += "bei Anreise in spätestens " + offer.timeSpan + " Tagen ";
    							}

    						}


    						if (offer.fromdate) {
    							offerText += " " + this.voMsg("offer.from") + " " + DateEx.formatDate(this.offers[i].fromdate, undefined, VOFFICE.mlang) + " ";
    						}
    						if (offer.tilldate) {
    							offerText += " " + this.voMsg("offer.to") + " " + DateEx.formatDate(this.offers[i].tilldate, undefined, VOFFICE.mlang) + " ";
    						}

    						if (offer.minStay > 1) {
    							offerText += "(" + this.voMsg("offers.min.night") + " " + offer.minStay + " " + this.voMsg("offer.nights") + ")";
    						}

    						offerText += "</li>";
    					}
    				}
    			}

    			return offerText;
    		},
    		title: function() {
    			return this.voMsg('offer.title');
    		}
    	}
    };
</script>